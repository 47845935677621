<style>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: A4 landscape;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    #title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    #info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }
</style>

<template>
    <div>
        <!-- <img :src="logoSDN" id="logo" /> -->
        <p id="title">Stock Relocation Delivery Order</p>
        <div class="space-for-logo"></div>
        <div id="info">
            <table class="remove_border">
                <tr>
                    <th>STO Doc. Number</th>
                    <th>:</th>
                    <th>{{ code }}</th>
                </tr>
                <tr>
                    <th>DO [STO] Number</th>
                    <th>:</th>
                    <th>{{ code_do }}</th>
                </tr>
                <tr>
                    <th>Source Warehouse</th>
                    <th>:</th>
                    <th>{{ selectedSrcWarehouse }}</th>
                </tr>
                <tr>
                    <th>Destination Warehouse</th>
                    <th>:</th>
                    <th>{{ selectedDestWarehouse }}</th>
                </tr>
                <tr>
                    <th>Delivery Date</th>
                    <th>:</th>
                    <th>{{ formatDate(reqDeliveryDate) }}</th>
                </tr>
                <tr>
                    <th>Note</th>
                    <th>:</th>
                    <th>{{ formatDate(eta) }}</th>
                </tr>
            </table>
        </div>
        <table id="table-list">
            <thead>
                <tr>
                    <th style="text-align: center" colspan="6">
                        Rincian Proses Stock Relocation
                    </th>
                </tr>
                <tr></tr>
                <tr>
                    <th style="vertical-align: middle; text-align: center;" rowspan="2">SKU</th>
                    <th style="text-align: center;" colspan="2">STO Qty</th>
                    <th style="text-align: center;" colspan="3">DO Qty</th>
                </tr>
                <tr>
                    <th width="10%">HU</th>
                    <th width="10%">Qty</th>
                    <th width="10%">HU</th>
                    <th width="10%">Qty</th>
                    <th width="10%">Batch #</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(skuLine, index) in skuLines">
                    <tr :key="index">
                        <td class="align-top" 
                            :rowspan="skuLines[index].do_lines.length"
                        >{{ skuLine.selected_sku.label }}</td>
                        <td class="align-top" 
                            :rowspan="skuLines[index].do_lines.length"
                        >{{ skuLine.selected_hu }}</td>
                        <td class="align-top" 
                            :rowspan="skuLines[index].do_lines.length"
                        >{{ skuLine.qty }}</td>
                        <td>{{ skuLines[index].do_lines[0].selected_hu }}</td>
                        <td>{{ skuLines[index].do_lines[0].qty }}</td>
                        <td>{{ skuLines[index].do_lines[0].batch }}</td>
                    </tr>

                    <template v-for="(doLine, indexDoLine) in skuLines[index].do_lines">
                        <tr :key="index + '-' + indexDoLine" v-if="indexDoLine > 0">
                            <td class="td">{{ skuLines[index].do_lines[indexDoLine].selected_hu }}</td>
                            <td class="td">{{ skuLines[index].do_lines[indexDoLine].qty }}</td>
                            <td class="td">{{ skuLines[index].do_lines[indexDoLine].batch }}</td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment-timezone";
import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    data() {
        return {
            id: null,
            table: this.tableDefaultState(),
            code: null,
            code_do: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            skuLines: [
                {
                    stock_relocation_plan_line_id: 0,
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                    do_lines: [{
                        selected_hu: null,
                        qty: 0,
                        batch: "",
                    }]
                }
            ],
            logoSDN: logoSDN,
            showInfo: false,
        }
    },
    mounted() {
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()

        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.print();
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
            };
        },
        async print() {
            await this.getData()
            await window.print()
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(
                this.$store.state.outbound.simpleStoDo.baseUrlPath + "/" + this.id
            );
            if (resp.code == 200) {
                this.code = resp.data.code
                this.code_do = resp.data.code_do
                this.selectedSrcWarehouse = resp.data.selected_src_warehouse.label
                this.selectedDestWarehouse = resp.data.selected_dest_warehouse.label
                this.reqDeliveryDate = moment(resp.data.req_delivery_date).toDate()
                this.eta = moment(resp.data.eta).toDate()
                this.notes = resp.data.notes
                this.skuLines = resp.data.sku_lines
                this.table.data = resp.data
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD MMM YYYY');
            };
        },
    },
}
</script>